import Button from 'components/Button'
import { navigate } from 'gatsby'
import AppLayout from 'layouts/AppLayout'
import PaperPlane from '../../../../static/images/icons/paper-plane.svg'

import './LoanConclusion.scss'

const LoanConclusion = () => {
  return (
    <AppLayout useContentHeader={false} contentClassName="loan-conclusion">
      <PaperPlane />
      <h1 className="loan-conclusion__title">Solicitação enviada</h1>
      <p className="loan-conclusion__description">
        agora basta abrir sua conta em nosso app que seu dinheiro cairá direto
        por lá.
      </p>
      <Button
        className="loan-conclusion__button"
        onClick={() => navigate('/abrir-conta')}
        // tracking={{
        //   htmlId: '4619075f-d49c-4282-9b18-fcc9cf0e0f54',
        //   location: 'cards-conclusion',
        //   name: 'click-cards-conclusion-open-account',
        //   actionOutcome: 'open-account',
        //   outboundUrl: `${BASE_URL}/abrir-conta`,
        // }}
      >
        Abrir conta
      </Button>
    </AppLayout>
  )
}

export default LoanConclusion
